"use strict";

let mapEl,
    $mapErrorMessageEl,
    nearToMeMapControlEl,
    $nearToMeMapControlEl,
    storesMapData = null,
    storesData = null,
    mapIsLoaded = false;

const markers = {},

    initStoresMap = function(options) {
        const defaults = {
            storeSlug: null,
            scrollIntoView: false,
            selectStoreButtonClickEventListener: undefined,
        };
        options = $.extend({}, defaults, options);

        if (!window.google) {
            return;
        }

        clearMapErrorMessage();

        const postMapLoadCallback = function() {
            if (options.storeSlug === null && storesMapData !== null && typeof storesMapData.shippingStoreSlug !== 'undefined') {
                // The user has come back to this page while in the middle
                // of a checkout, so select their previously-selected store
                options.storeSlug = storesMapData.shippingStoreSlug;
            }
            if (typeof markers[options.storeSlug] !== 'undefined') {
                google.maps.event.trigger(markers[options.storeSlug], 'click');
            }
            if (options.scrollIntoView) {
                mapEl.scrollIntoView && mapEl.scrollIntoView();
            }
        };

        if (mapIsLoaded) {
            // Just do post-map-load stuff, and return
            postMapLoadCallback();
            return;
        }

        const map = new google.maps.Map(mapEl, {
                center: new google.maps.LatLng(0, 0),
                zoom: 10,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                streetViewControl: false
            }),
            infoWindow = new google.maps.InfoWindow(),
            bounds = new google.maps.LatLngBounds();

        // Create markers and info windows
        if (storesData !== null) {
            $.each(storesData, function(i, store) {
                const latLng = new google.maps.LatLng(store.latitude, store.longitude),
                    marker = new google.maps.Marker({ map: map, position: latLng }),
                    html = function(strings, selectStoreButtonHtml) {
                        return `<div class="info-window" id="info-window-${ store.slug }">
                                <strong>${ store.name }</strong><br />
                                ${ store.address.line1.replace("\n", "<br />") }<br />
                                ${ store.address.line2 ? `${ store.address.line2.replace("\n", "<br />") }<br />` : "" }
                                ${ store.address.line3 ? `${ store.address.line3.replace("\n", "<br />") }<br />` : "" }
                                ${ store.address.line4 ? `${ store.address.line4.replace("\n", "<br />") }<br />` : "" }
                                ${ store.address.country }
                                ${ selectStoreButtonHtml }
                                </div>`
                    };
                let selectStoreButtonHtml = '';
                bounds.extend(latLng);
                if (typeof options.selectStoreButtonClickEventListener !== 'undefined') {
                    selectStoreButtonHtml = '<div class="text-center m-1">' +
                                            '<button class="btn btn-secondary btn-sm" type="button">Select store</button>' +
                                            '</div>';
                    google.maps.event.addListener(infoWindow, 'domready', function() {
                        $(`#info-window-${ store.slug } button`).click(options.selectStoreButtonClickEventListener);
                    });
                }
                google.maps.event.addListener(marker, 'click', function() {
                    map.setZoom(15);
                    map.setCenter(marker.getPosition());
                    infoWindow.setContent(html`${ selectStoreButtonHtml }`);
                    infoWindow.open(map, marker);
                    clearMapErrorMessage();
                });
                markers[store.slug] = marker;
            });
        }

        // Add "near to me" custom control
        map.controls[google.maps.ControlPosition.RIGHT_TOP].push(nearToMeMapControlEl);
        const $nearToMeMapControlInnerEl = $nearToMeMapControlEl.find('div > div'),
            nearToMeMapControlInnerElHtml = $nearToMeMapControlInnerEl.html();
        google.maps.event.addDomListener(nearToMeMapControlEl, 'click', function() {
            if (storesMapData === null) {
                return;
            }
            if (!('geolocation' in navigator)) {
                displayMapErrorMessage("Your browser does not support retrieving your location.");
                return;
            }
            $nearToMeMapControlInnerEl.html("Finding stores…");
            navigator.geolocation.getCurrentPosition(function(position) {
                const url = `${ storesMapData.storeListApiUrl }?${ (typeof options.storeListApiUrlParams !== 'undefined') ? `${ options.storeListApiUrlParams }&` : '' }near=${ position.coords.latitude },${ position.coords.longitude }`;
                $.ajax(url, {
                    method: 'GET',
                    success: function(data, textStatus, jqXHR) {
                        if (Array.isArray(data) && data.length > 0) {
                            initStoresMap({
                                storeSlug: data[0].slug,
                                storeListApiUrlParams: options.storeListApiUrlParams,
                            });
                            $nearToMeMapControlInnerEl.html(nearToMeMapControlInnerElHtml);
                        }
                    },
                    error: function(jqXHR, textStatus, errorThrown) {
                        displayMapErrorMessage("We were unable to find a store near to your location.");
                        $nearToMeMapControlInnerEl.html(nearToMeMapControlInnerElHtml);
                    },
                });
            }, function(error) {
                displayMapErrorMessage("We were unable to determine your location.<br />Please find a store manually on the map above.");
                $nearToMeMapControlInnerEl.html(nearToMeMapControlInnerElHtml);
            });
        });

        google.maps.event.addListenerOnce(map, 'tilesloaded', function() {
            google.maps.event.addListenerOnce(map, 'bounds_changed', function() {
                // Fix the zoom, which is too high when there is only one
                // store
                if (map.getZoom() > 15) {
                    map.setZoom(15);
                }
            });
            map.fitBounds(bounds);
        });

        google.maps.event.addListenerOnce(map, 'tilesloaded', postMapLoadCallback);

        mapIsLoaded = true;
    },

    clearMapErrorMessage = function() {
        $mapErrorMessageEl.hide();
    },

    displayMapErrorMessage = function(message) {
        $mapErrorMessageEl.html(message);
        $mapErrorMessageEl.show();
    },

    fetchStoresData = function(options) {
        // If Google Maps hasn't loaded yet, try again a bit later.
        if (!window.google) {
            setTimeout(fetchStoresData, 5000, options);
            return;
        }

        // Show the map container, which was initially hidden
        $(mapEl).addClass('stores-map');

        if (storesMapData === null) {
            return;
        }
        // This function could have been called directly, or as an event handler
        if (typeof options === 'undefined') {
            options = {};
        } else if (typeof options.data !== 'undefined') {
            options = options.data;
        }
        const url = `${ storesMapData.storeListApiUrl }${ (typeof options.storeListApiUrlParams !== 'undefined') ? `?${ options.storeListApiUrlParams }` : '' }`,
            initStoresMapOptions = options.initStoresMapOptions;

        $.ajax(url, {
            method: 'GET',
            success: function(data, textStatus, jqXHR) {
                if (Array.isArray(data)) {
                    storesData = data;
                }
                initStoresMap(initStoresMapOptions);
                // Do this here, so that it is not visible before the map
                // has been loaded
                $mapErrorMessageEl.addClass('alert alert-warning');
            },
            error: function(jqXHR, textStatus, errorThrown) {
                displayMapErrorMessage("The map could not be loaded.");
            },
        });
    },

    maybeLoadMap = function(options) {
        // This function could have been called directly, or as an event handler
        if (typeof options === 'undefined') {
            options = {};
        } else if (typeof options.data !== 'undefined') {
            options = options.data;
        }
        const $scrollableEl = options.$scrollableEl;

        if (($scrollableEl.scrollTop() + $scrollableEl.height()) >= $(mapEl).offset().top) {
            // Unbind the "scroll" event handler
            $scrollableEl.off('scroll', maybeLoadMap);
            fetchStoresData();
        }
    };

$(function() {
    mapEl = document.getElementById('map');
    $mapErrorMessageEl = $('#map-error-message');
    nearToMeMapControlEl = document.getElementById('near-to-me');
    $nearToMeMapControlEl = $(nearToMeMapControlEl);

    // Hide the map container initially
    $(mapEl).removeClass('stores-map');

    const storesMapDataEl = document.getElementById('stores-map-data');
    if (storesMapDataEl !== null) {
        try {
            storesMapData = JSON.parse(storesMapDataEl.textContent);
        } catch {}
    }
});

export { storesData, initStoresMap, clearMapErrorMessage, fetchStoresData, maybeLoadMap }
