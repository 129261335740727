"use strict";

import { initStoresMap, clearMapErrorMessage, fetchStoresData } from './stores_map.js';

const scrollToCollectorEl = function() {
    const pickupCollectorEl = document.querySelector('.checkout-pickup-collector');
    pickupCollectorEl.scrollIntoView && pickupCollectorEl.scrollIntoView();
}

const handleSelectStoreButtonClick = function() {
    const storeSlug = $(this).closest('.info-window').attr('id').replace('info-window-', '');
    $(`#store-${ storeSlug }`).closest('.btn').button('toggle');
    scrollToCollectorEl();
    clearMapErrorMessage();
};

$(function() {
    const storeListApiUrlParams = 'pickup=true';

    $('.checkout-pickup-store-select input').click(function(e) {
        const storeSlug = $(this).attr('id').replace('store-', '');
        initStoresMap({
            storeSlug: storeSlug,
            storeListApiUrlParams: storeListApiUrlParams,
        });

        if($(this).is(':checked')) {
            scrollToCollectorEl();
        }
    });

    const fetchStoresDataOptions = {
        storeListApiUrlParams: storeListApiUrlParams,
        initStoresMapOptions: {
            // Register the handling of "select store" button click events
            selectStoreButtonClickEventListener: handleSelectStoreButtonClick,
            storeListApiUrlParams: storeListApiUrlParams,
        },
    }
    // Load the map if the "click & collect" tab is already open, or as soon as
    // it is selected
    if ($('#checkout-pickup-body').is('.collapse.show')) {
        fetchStoresData(fetchStoresDataOptions);
    } else {
        $('#checkout-pickup-body').one('show.bs.collapse', null, fetchStoresDataOptions, fetchStoresData);
    }
});
